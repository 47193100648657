<template>
  <div class="p-d-flex p-flex-column">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div class="p-grid">
      <div class="p-col">
        <div class="card p-mb-2">
          <TabView
            class="tabview-custom"
            ref="tabview2"
            v-model:activeIndex="active"
          >
            <TabPanel>
              <template #header>
                <span>Global</span>
                <br />
                <i class="pi pi-calendar"></i>
              </template>
              <div class="p-d-flex">
                <div class="p-col" v-if="isLoading">
                  <p><global-actions :settings="settings.global"/></p>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Information</span>
                <i class="pi pi-user"></i>
              </template>
              <div class="p-d-flex">
                <div class="p-col" v-if="isLoading">
                  <p><information-actions :settings="settings.information"/></p>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Societe</span>
                <i class="pi pi-search"></i>
              </template>
              <div class="p-d-flex">
                <div class="p-col" v-if="isLoading">
                  <p><company-actions :settings="settings.company"/></p>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Calendar</span>
                <i class="pi pi-calendar"></i>
              </template>
              <div class="p-d-flex">
                <div class="p-col" v-if="isLoading">
                  <p><calendar-actions :settings="{first_day: settings.first_day, last_day: settings.last_day, first_hour: settings.first_hour, last_hour: settings.last_hour}"/></p>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalActions from '@/components/Settings/Global.vue';
import InformationActions from '@/components/Settings/Information.vue';
import CompanyActions from '@/components/Settings/Company.vue';
import CalendarActions from '@/components/Settings/Calendar.vue';
import useI18n from '@/plugins/useI18n';
import {ref, reactive, computed } from 'vue';
import axios from '@/plugins/axios';
import {GET_USERDATA} from '@/store/types';
import {useStore} from 'vuex';


export default {
  components: {
    GlobalActions,
    InformationActions,
    CompanyActions,
    CalendarActions
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: '/' },
      breadcrumbItems: [{ label: 'Settings', to: '/profile/settings' }],
      active: 0
    };
  },
  setup() {
    const { i18n } = useI18n();

    const types = ['information', 'company', 'first_day', 'last_day', 'first_hour', 'last_hour'];

    const settings = reactive({});

    const isLoading = ref(false);

    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);
    
    async function getSettings() {
      try {
        var res = await axios.get(`setting?owner_id=${userData.value.id}`);
        const response = res.data.data.items;

        for (let i = 0; i < response.length; i++) {
          types.forEach((el) => {
            if (response[i].type == el) {
              settings[el] = response[i];
            }
          });
        }
        isLoading.value = true;
      } catch {
        isLoading.value = false;
      }
    }
    getSettings();

    return {
      i18n,
      settings,
      isLoading
    };
  }
};
</script>
<style lang="scss" scoped>
.tabview-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}
</style>