<template>
  <div class="terms-conditions">
    <div class="p-formgrid p-grid">
      <div class="p-field p-col update-setting" :class="{disabled: !can('v1.setting.update')}">
        <label for="name2">Language</label>
        <div class="overlay"></div>
        <Dropdown
          id="userData.language_id"
          @change="changeLang"
          v-model="selectedLang"
          :options="langs"
          optionLabel="label"
          optionValue="name"
          placeholder="Select Language"
        ></Dropdown>
      </div>
    </div>
    <div v-if="showResult" class="text-left font-semibold mt-4">
      <p v-if="isError" class="text-red-700">Oops, something went wrong!</p>
      <p v-if="!isError" class="text-green-700">Saved successfully</p>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import useI18n from '@/plugins/useI18n';
import { useStore } from 'vuex';
import {
  SET_LANGUAGE,
  GET_LANGUAGE,
  UPDATE_USER,
  GET_USERDATA
} from '../../store/types';
import { LANGS } from '@/store/constant';

export default {
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters[GET_LANGUAGE]);
    const { i18n } = useI18n();
    const langs = ref(LANGS);
    const selectedLang = ref();
    const showResult = ref(false);
    const isError = ref(false);
    const userData = computed(() => store.getters[GET_USERDATA]);

    if (lang.value) {
      selectedLang.value = lang.value;
      i18n.locale.value = lang.value;
    }

    async function changeLang() {
      showResult.value = false;
      isError.value = false;
      try {
        userData.value.language_id = selectedLang.value;
        console.log(userData);
        await store.dispatch(UPDATE_USER, userData.value);
        store.commit(SET_LANGUAGE, selectedLang.value);
        i18n.locale.value = selectedLang.value;
        showResult.value = true;
        isError.value = false;
      } catch {
        showResult.value = true;
        isError.value = true;
      }
    }

    return {
      i18n,
      langs,
      selectedLang,
      changeLang,
      showResult,
      isError
    };
  }
};
</script>
<style lang="scss">
.update-setting {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
  }
  &.disabled {
    .p-dropdown-trigger {
      display: none;
    }
    .overlay {
      display: block;
    }
  }
}
</style>