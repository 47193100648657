<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h3>Accept only :</h3>
      <div class="card">
        <div
          v-for="category of categories"
          :key="category.key"
          class="p-field-checkbox update-setting"
          :class="{disabled: !can('v1.setting.update')}"
        >
          <div class="overlay"></div>

          <Checkbox
            :id="category.key"
            name="category"
            :value="category"
            v-model="selectedCategories"
          />
          <label :for="category.key">{{ category.name }}</label>
        </div>
        <Button
          type="button"
          label="Update"
          @click="saveInformationSetting(selectedCategories)"
          v-if="can('v1.setting.update')"
        >
          <i v-if="isLoading" class="pi pi-spin pi-spinner"></i>
          <i v-if="!isLoading" class="pi pi-check"></i>
          <spinner v-if="isLoading" color="white" />
          <span v-if="!isLoading">Save</span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, computed } from 'vue';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import { GET_USERDATA } from '@/store/types';
import { useStore } from 'vuex';
import { API } from '@/plugins/api';

export default {
  data() {
    return {
      categories: [
        { name: 'Task', key: 'Task' },
        { name: 'Event', key: 'Event' },
        { name: 'Rdv', key: 'Rdv' }
      ]
    };
  },
  props: {
    settings: {
      type: Object
    }
  },
  components: {
    Spinner
  },
  setup(props) {
    const { i18n } = useI18n();
    const information_actions = reactive({
      Task: false,
      RDV: false,
      Event: false
    });
    const isLoading = ref(false);
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);

    const api = new API();
    async function saveInformationSetting(selectedCategories) {
      isLoading.value = true;
      try {
        const value = [];
        for (let i = 0; i < selectedCategories.length; i++) {
          value.push(selectedCategories[i].name);
        }
        const id = props.settings ? props.settings._id : null;
        const payload = {
          owner_id: userData.value.id,
          type: 'company',
          value
        };
        if (id) {
          api.update('setting', 'Company', id, payload);
        } else {
          await axios.post('/setting', payload);
        }
      } catch {
        isLoading.value = false;
      }
      isLoading.value = false;
    }
    const selectedCategories = ref([]);

    getSettings();

    async function getSettings() {
      if (props.settings) {
        props.settings.value.forEach((el) => {
          if (el !== null) {
            selectedCategories.value.push({
              name: el,
              key: el
            });
          }
        });
      }
      selectedCategories.value.slice(0, selectedCategories.value.length);
    }

    return {
      i18n,
      information_actions,
      saveInformationSetting,
      selectedCategories,
      isLoading
    };
  }
};
</script>