<template>
  <div class="flex items-center update-setting" ref="root">
    <p
      class="font-semibold p-mb-2"
      :class="{ disabled: !can('v1.setting.update') }"
    >
      {{ i18n.$t('First Day') }}:
    </p>
    <div class="overlay"></div>
    <Dropdown
      v-model="data.first_day"
      :options="days"
      optionLabel="name"
      optionValue="value"
      class="first-dropdown first-day"
      @change="updateSetting('first_day', data.first_day)"
    />
  </div>
  <div class="flex items-center p-mt-2 update-setting" ref="root">
    <p
      class="font-semibold p-mb-2"
      :class="{ disabled: !can('v1.setting.update') }"
    >
      {{ i18n.$t('Last Day') }}:
    </p>
    <div class="overlay"></div>
    <Dropdown
      v-model="data.last_day"
      :options="days"
      optionLabel="name"
      optionValue="value"
      class="first-dropdown first-day"
      @change="updateSetting('last_day', data.last_day)"
    />
  </div>
  <div
    class="flex items-center p-mt-2 update-setting"
    :class="{ disabled: !can('v1.setting.update') }"
  >
    <p class="font-semibold p-mb-2">{{ i18n.$t('First Hour') }}:</p>
    <div class="overlay"></div>
    <Dropdown
      v-model="data.first_hour"
      :options="hours"
      optionLabel="name"
      optionValue="value"
      class="first-dropdown first-hour"
      @change="updateSetting('first_hour', data.first_hour)"
    />
  </div>
  <div
    class="flex items-center p-mt-2 update-setting"
    :class="{ disabled: !can('v1.setting.update') }"
  >
    <p class="font-semibold p-mb-2">{{ i18n.$t('Last Hour') }}:</p>
    <div class="overlay"></div>
    <Dropdown
      v-model="data.last_hour"
      :options="hours"
      optionLabel="name"
      optionValue="value"
      class="first-dropdown last-hour"
      @change="updateSetting('last_hour', data.last_hour)"
    />
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { computed, reactive } from 'vue';
import axios from '@/plugins/axios';
import { GET_USERDATA } from '@/store/types';
import { useStore } from 'vuex';
import { API } from '@/plugins/api';
import { hours } from '@/store/constant.js';
import { defaultValues } from '../../store/data/schedule';
export default {
  props: {
    settings: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);

    const defaults = defaultValues();
    const data = reactive(defaults);

    const settings = props.settings;
    for (const key in settings) {
      if (settings[key]) {
        console.log(settings[key], 'settings');
        data[key] = settings[key].value;
      }
    }

    const days = [
      { value: 0, name: i18n.$t('Monday') },
      { value: 1, name: i18n.$t('Tuesday') },
      { value: 2, name: i18n.$t('Wednesday') },
      { value: 3, name: i18n.$t('Thursday') },
      { value: 4, name: i18n.$t('Friday') },
      { value: 5, name: i18n.$t('Saturday') },
      { value: 6, name: i18n.$t('Sunday') }
    ];

    const api = new API();

    async function updateSetting(type, value) {
      const id = props.settings ? props.settings._id : null;
      const payload = {
        owner_id: userData.value.id,
        type: type,
        value: value
      };
      if (id) {
        api.update('setting', type, id, payload);
      } else {
        await axios.post(`/setting`, payload);
      }
    }

    return {
      i18n,
      days,
      data,
      updateSetting,
      hours
    };
  }
};
</script>

<style lang="scss" scoped>
.first-dropdown {
  width: 150px;
}
</style>
