export const WORKING_TIME_INTERVAL = 60; // means 60 minutes

export function getBaseSchedule() {
  return [{
      label: 'Mon',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
            startTime: '05:00',
            endTime: '12:00'
          },
          {
            startTime: '14:00',
            endTime: '16:00'
          }
        ]
      }]
    },
    {
      label: 'Tue',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '10:00',
          endTime: '12:00'
        }]
      }]
    },
    {
      label: 'Wed',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '10:00',
          endTime: '12:00'
        }]
      }]
    },
    {
      label: 'Thu',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '10:00',
          endTime: '12:00'
        }]
      }]
    },
    {
      label: 'Fri',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '10:00',
          endTime: '12:00'
        }]
      }]
    },
    {
      label: 'Sat',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '08:00',
          endTime: '12:00'
        }]
      }]
    },
    {
      label: 'Sun',
      active: true,
      sections: [{
        openTime: '00:00',
        closeTime: '24:00',
        workPeriods: [{
          startTime: '08:00',
          endTime: '12:00'
        }]
      }]
    }
  ];
}
export function defaultValues() {
  return {
    first_day: 0, // default value monday
    last_day: 4, // default value Friday
    first_hour: 9, // default value 09h
    last_hour: 18 // default value 18h
  };
}